<template>
  <div class="layer_05">
    <div class="layer_top">
      <h2 class="layer_top_title">
        <!-- TODO: 서드파티 광고 추가시 헤더 명 수정 -->
        일반 광고 {{ id ? "수정" : "등록" }}
      </h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="layer_01_box">
      <form>
        <table class="layer_01_table">
          <colgroup>
            <col style="width: 107px" />
            <col style="width: 1147px" />
          </colgroup>
          <tr class="layer_tbl">
            <td class="layer_tbl_title">구분</td>
            <td>
              <input
                type="checkbox"
                id="home"
                class="big_check ml_0"
                value="home"
                v-model="type"
              />
              <label for="home" class="check_label_02">홈</label>
              <input
                type="checkbox"
                id="mission_home"
                class="big_check"
                value="mission.home"
                v-model="type"
              />
              <label for="mission_home" class="check_label_02">미션 - 홈</label>
              <input
                type="checkbox"
                id="mission_reward"
                class="big_check"
                value="mission.reward"
                v-model="type"
              />
              <label for="mission_reward" class="check_label_02"
                >미션 - 리워드</label
              >
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">제목</td>
            <td class="pb_15">
              <input type="text" class="text_box_03" v-model="title" />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="big_box_title pb_15">내용</td>
            <td class="pb_15">
              <textarea
                class="editor"
                style="width: 100%; height: 200px"
                v-model="text"
              ></textarea>
              <!--
												[D]
												에디터 입니다.
											 --></td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_20">커버 이미지</td>
            <td class="pb_20 standard">
              <input
                type="text"
                class="text_box_02 mr_30"
                v-model="cover.name"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="cover_upload"
                ref="refCover"
                @change="previewFile('cover')"
              />
              <label for="cover_upload" class="file_btn"> 찾아보기 </label>
              <div
                v-if="selectFile.cover || cover.name"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ cover.name }}</span>
                <div
                  class="cancel_img"
                  @click.prevent.stop="removeFile('cover')"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
          </tr>
          <!-- <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">태그</td>
            <td class="pb_15 standard">
              <div class="input_area_02">
                <input
                  type="text"
                  class="input_box_02 pl_12"
                  v-model="tag"
                  placeholder=", 로 구분"
                />
              </div>
            </td>
          </tr> -->
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">링크</td>
            <td>
              <div class="input_area_02">
                <input type="text" class="input_box_02 pl_12" v-model="url" />
              </div>
            </td>
          </tr>
          <h3 style="margin-top: 30px">(구)스탬프 팝</h3>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_20">이미지 첨부</td>
            <td class="pb_20 standard">
              <input
                type="text"
                class="text_box_02 mr_30"
                v-model="file.name"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="picture_upload"
                ref="refPicture"
                @change="previewFile('picture')"
              />
              <label for="picture_upload" class="file_btn"> 찾아보기 </label>
              <div
                v-if="selectFile.picture || file.name"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ file.name }}</span>
                <div
                  class="cancel_img"
                  @click.prevent.stop="removeFile('picture')"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </form>
      <div class="btn_center_wrapper">
        <base-button type="primary-lg" @click="edit">{{
          id ? "수정" : "등록"
        }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import Dialog from "@/components/Dialog.vue";

export default {
  components: {},
  props: {
    id: Number,
  },
  data() {
    return {
      type: ["home"],
      title: "",
      text: "",
      tag: "",
      url: "",
      file: {
        url: "",
        name: "",
      },
      cover: {
        url: "",
        name: "",
      },
      selectFile: {
        picture: null,
        cover: null,
      },
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (this.id) {
        await client.advertisementFindone(this.id).then((response) => {
          const { data } = response;
          console.log(data);
          this.type = data.type.split(",");
          this.title = data.title;
          this.text = data.text;
          this.tag = data.tag;
          this.url = data.url;
          this.file.url = data.file[0] ? data.file[0].name : "";
          this.file.name = data.file[0] ? data.file[0].name : "";
          this.cover.url = data.cover.name;
          this.cover.name = data.cover.name;
        });
      }
    },
    async edit() {
      if (this.selectFile.picture) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.picture);
        await client.fileAdd(form).then(
          (response) => {
            const { data } = response;
            this.file.url = data.name;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }
      if (this.selectFile.cover) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.cover);
        await client.fileAdd(form).then(
          (response) => {
            const { data } = response;
            this.cover.url = data.name;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }
      let params = {
        type: this.type.toString(),
        title: this.title,
        text: this.text,
        tag: this.tag,
        url: this.url,
        file: [
          {
            url: this.file.url,
          },
        ],
        cover: this.cover.url,
      };

      if (!this.title) {
        alert("제목을 입력해주세요");
      } else if (!this.cover.url) {
        alert("광고 이미지를 선택해주세요");
      } else if (this.url && !this.isValidUrl(this.url)) {
        alert("유효하지 않은 url입니다");
      } else {
        if (this.id) {
          await client.advertisementModify(this.id, params).then((response) => {
            console.log(response);
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: "정상적으로 수정되었습니다.",
              },
              {
                width: 400,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
            this.$emit("close", { refresh: true });
          });
        } else {
          await client.advertisementAdd(params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "광고가 정상적으로 등록되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    },
    removeFile(type) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              if (this.selectFile[type]) {
                // 로컬에서 선택한 파일이 있을경우
                this.selectFile[type] = null;
              }
              if (type == "picture") {
                this.file.name = "";
                this.file.url = "";
              }
              if (type == "cover") {
                this.cover.name = "";
                this.cover.url = "";
              }
            }
          },
        }
      );
    },
    previewFile(type) {
      let selectFile = null;
      if (type == "picture") {
        selectFile = this.$refs.refPicture;
      } else if (type == "cover") {
        selectFile = this.$refs.refCover;
      }
      if (0 < selectFile.files.length) {
        this.selectFile[type] = selectFile.files[0];
        let fileExt = this.selectFile[type].name.substring(
          this.selectFile[type].name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          ["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt) &&
          this.selectFile[type].size <= 1048576
        ) {
          if (type == "picture") {
            this.file.name = this.selectFile["picture"].name;
          } else if (type == "cover") {
            this.cover.name = this.selectFile["cover"].name;
          }
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectFile[type] = null;
        }
      } else {
        this.selectFile[type] = null;
      }
    },

    // url 유효성 검사
    isValidUrl(url) {
      let regUrl =
        /(http|https):\/\/((\w+)[.])+(asia|biz|cc|cn|com|de|eu|in|info|jobs|jp|kr|mobi|mx|name|net|nz|org|travel|tv|tw|uk|us)(\/(\w*))*$/i;
      return regUrl.test(url);
    },
  },
};
</script>
