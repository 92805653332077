<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">광고</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <table>
          <colgroup>
            <col style="width: 120px" />
            <col style="width: 369px" />
            <col style="width: 120px" />
            <col style="width: 369px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">제목</td>
            <td class="box_style_02">
              {{ title }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">구분</td>
            <td class="box_style_02" colspan="3">
              {{ type }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style_lg">내용</td>
            <td class="box_style_03" colspan="3">
              {{ text }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style_lg" v-if="picture">
              이미지
            </td>
            <td class="box_style_03" v-if="picture">
              <img
                :src="`${fileUrl}${picture}`"
                alt="이미지"
                style="
                  width: auto;
                  max-width: 120px;
                  height: 100%;
                  max-height: 100px;
                "
              />
            </td>
            <td class="box_style_02 list_title_style_lg">커버 이미지</td>
            <td class="box_style_03">
              <img
                :src="`${cover.url}${cover.path}`"
                alt="커버 이미지"
                style="
                  width: auto;
                  max-width: 120px;
                  height: 100%;
                  max-height: 100px;
                "
              />
            </td>
          </tr>
          <!-- <tr class="line_style">
            <td class="box_style_02 list_title_style">태그</td>
            <td class="box_style_02" colspan="3">
              {{ tag }}
            </td>
          </tr> -->
          <tr class="line_style">
            <td class="box_style_02 list_title_style">홈페이지</td>
            <td class="box_style_02" colspan="3">
              {{ url }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      type: "",
      title: "",
      tag: "",
      url: "",
      fileUrl: "",
      picture: "",
      cover: "",
      text: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.advertisementFindone(this.id).then(
        (response) => {
          const { data } = response;
          this.title = data.title;
          this.type = data.type.split(",");
          this.type.splice(this.type.indexOf("home"), 1, "홈");
          this.type.splice(this.type.indexOf("mission.badge"), 1, "미션-배지");
          this.type.splice(this.type.indexOf("mission.home"), 1, "미션-홈");
          this.type.splice(
            this.type.indexOf("mission.reward"),
            1,
            "미션-리워드"
          );
          this.type = this.type.toString();
          this.tag = data.tag;
          this.url = data.url;
          this.picture = data.file[0] ? data.file[0].path : "";
          this.fileUrl = data.file[0] ? data.file[0].url : "";
          this.cover = data.cover;
          this.text = data.text;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
